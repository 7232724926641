import axios from 'axios'

export const setAuthToken = (token: string | undefined) => {
    if (token) {
        // localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common['Authorization']
        // localStorage.removeItem('token')
    }
}

