import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { googleLogin } from '../../components/redux/reducers/authenticationSlice'
import { useAppDispatch } from '../../components/redux/hooks'
import { LoginCredentials } from '../../models/LoginCredentials.model'

export default function Login() {
    const dispatch = useAppDispatch()
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    // const handleSubmit = () => {
    //     const loginCredentials: LoginCredentials = {
    //         username: user,
    //         password: password,
    //     }
    //     dispatch(login(loginCredentials))
    // }

    const login = () =>{
        dispatch(googleLogin())
    }

    return (
        // <Form>
        //     <Form.Group className="mb-3" controlId="formBasicEmail">
        //         <Form.Label>Email address</Form.Label>
        //         <Form.Control
        //             type="email"
        //             placeholder="Enter email"
        //             value={user}
        //             onChange={(e) => setUser(e.target.value)}
        //         />
        //     </Form.Group>

        //     <Form.Group className="mb-3" controlId="formBasicPassword">
        //         <Form.Label>Password</Form.Label>
        //         <Form.Control
        //             type="password"
        //             placeholder="Password"
        //             value={password}
        //             onChange={(e) => setPassword(e.target.value)}
        //         />
        //     </Form.Group>
        //     <Form.Group className="mb-3" controlId="formBasicCheckbox">
        //         <Form.Check
        //             type="checkbox"
        //             label="Recordarme"
        //             checked={rememberMe}
        //             onChange={(e) => setRememberMe(!rememberMe)}
        //         />
        //     </Form.Group>
        //     <Button variant="primary" type="submit" onClick={() => handleSubmit()}>
        //         Submit
        //     </Button>
        // </Form>
        <Button onClick={() => login()}>Iniciar sesion con Google</Button>
    )
}
