import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Form, Modal, Row } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../components/redux/hooks'
import { itemState, updateItem } from '../../components/redux/reducers/itemSlice.ts'
import { Item } from '../../models/Item.model'

export interface Props {
    show: boolean
    handleClose: () => void
    reloadPromotions: () => void
    item: Item
}

const ItemEditModal = ({ show, handleClose, item, reloadPromotions }: Props) => {
    const { status, errors } = useAppSelector(itemState)
    const dispatch = useAppDispatch()

    const [origin, setOrigin] = useState(item.origin)
    const [destination, setDestination] = useState(item.destination)
    const [description, setDescription] = useState(item.description)
    const [imageUrl, setImgeUrl] = useState(item.imageUrl)
    const [reload, setReload] = useState(false)

    useEffect(() => {
        if (status === 'idle' && reload) {
            reloadPromotions()
            close()
        }
    }, [status])
    const saveItem = () => {
        const itemToSave = {
            ...item,
            origin: origin,
            destination: destination,
            description: description,
            imageUrl: imageUrl,
        }
        dispatch(updateItem(itemToSave))
        setReload(true)
    }

    const close = () => {
        if (status !== 'loading') handleClose()
    }

    return (
        <Modal show={show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{`Editando ${item.origin} - ${item.destination}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Origen</Form.Label>
                                <Form.Control type="text" value={origin} onChange={(e) => setOrigin(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Destino</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={destination}
                                    onChange={(e) => setDestination(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Imagen URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={imageUrl}
                                    onChange={(e) => setImgeUrl(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                    <Row className='ms-2 mb-2 me-2'>
                        {errors.map((error, i) => (
                            <Alert key={`error-${i}`} variant="danger" >{error}</Alert>
                        ))}
                    </Row>
                </Card>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={saveItem}>
                    Guardar Cambios
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ItemEditModal
