import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { setAuthToken } from '../../../helpers/setAuthToken'
import { LoginCredentials } from '../../../models/LoginCredentials.model'
import { User } from '../../../models/User.model'
import { RootState } from '../store'
import { ApiError } from './itemSlice.ts'
import { auth, provider } from '../../../modeluies/firebase/config'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'




export interface AuthenticationState {
    user: User | undefined
    errors: string[]
    status: 'idle' | 'loading' | 'failed'
    sessionHasBeenFetched: boolean
    isAuthenticated: boolean
}

const initialState: AuthenticationState = {
    user: undefined,
    errors: [],
    status: 'idle',
    sessionHasBeenFetched: false,
    isAuthenticated: false
}

// export const login = createAsyncThunk(
//     'authentication/login',
//     async (loginCredentials: LoginCredentials, { rejectWithValue, dispatch }) => {
//         try {
//             const response = await axios.post(`/api/auth/signin`, loginCredentials)
//             const token = response.data.token
//             setAuthToken(token)
//             window.location.href = '/'
//         } catch (err: any) {
//             return rejectWithValue(err.response.data)
//         }
//     },
// )
export const googleLogin = createAsyncThunk(
    'authentication/login',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const result =  await signInWithPopup(auth, provider)
            const credential =GoogleAuthProvider.credentialFromResult(result) 
            console.log(credential)
            // const response = await axios.post(`/api/auth/signin`, loginCredentials)
            // const token = response.data.token
            setAuthToken(credential?.idToken)
            window.location.href = '/'
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    },
)


export const fetchUser = createAsyncThunk('authentication/retrieveUser', async (_, { rejectWithValue }) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        console.log(token)
        setAuthToken(token)
        const response = await axios.get(`/api/auth/account`)
        return response.data
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        logout: (state) => {
            setAuthToken(undefined)
            window.location.href = '/'
            state.errors = []
        },
        reset: (state) => {
            state.errors = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(googleLogin.pending, (state) => {
                state.status = 'loading'
                state.sessionHasBeenFetched = false
                state.isAuthenticated = false
            })
            .addCase(googleLogin.fulfilled, (state) => {
                state.status = 'idle'
                state.isAuthenticated = true
                state.sessionHasBeenFetched = true
            })
            .addCase(googleLogin.rejected, (state, action) => {
                const apiError = action.payload as ApiError
                state.sessionHasBeenFetched = true
                state.errors = apiError.errors
                state.status = 'failed'
            })
            .addCase(fetchUser.pending, (state) => {
                state.sessionHasBeenFetched = false
                state.isAuthenticated = false
                state.status = 'loading'
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.user = action.payload
                state.sessionHasBeenFetched = true
                state.isAuthenticated = true
                state.status = 'idle'
            })
            .addCase(fetchUser.rejected, (state, action) => {
                setAuthToken(undefined)
                state.sessionHasBeenFetched = true
                state.status = 'failed'
            })
    },
})

export const { reset, logout } = authenticationSlice.actions
export const authenticationState = (state: RootState) => state.authentication

export default authenticationSlice.reducer
