import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { Item } from '../../../models/Item.model'
import { RootState } from '../store'

export interface ApiError {
    status: string
    message: string
    errors: string[]
}
export interface ItemState {
    item: Item | undefined
    errors: string[]
    status: 'idle' | 'loading' | 'failed'
}

const initialState: ItemState = {
    item: undefined,
    errors: [],
    status: 'idle',
}

export const updateItem = createAsyncThunk('item/updateItem', async (item: Item, { rejectWithValue }) => {
    try {
        const response = await axios.put(`/api/items`, item)
        return response.data
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})

export const itemSlice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        reset: (state) => {
            state.errors = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateItem.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updateItem.fulfilled, (state, action) => {
                state.status = 'idle'
                state.item = action.payload
            })
            .addCase(updateItem.rejected, (state, action) => {
                const apiError = action.payload as ApiError
                state.errors = apiError.errors
                state.status = 'failed'
            })
    },
})

export const { reset } = itemSlice.actions
export const itemState = (state: RootState) => state.item

export default itemSlice.reducer
