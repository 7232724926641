import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { CountryData } from '../../../models/CountryData.model'
import { Country } from '../../../models/enums/Country.model'
import { PromotionStatus } from '../../../models/enums/PromotionStatus.model'
import { Item } from '../../../models/Item.model'
import { Promotion } from '../../../models/Promotion.model'
import { ReaderRequest } from '../../../models/ReaderRequest.model'
import { RootState } from '../store'

export interface PromotionState {
    promotions: Promotion[] | null
    errorMessage: string
    countryData: CountryData | null
    updated: boolean
    status: 'idle' | 'loading' | 'failed'
}

const initialState: PromotionState = {
    promotions: null,
    countryData: null,
    updated: false,
    errorMessage: '',
    status: 'idle',
}

export const fetchPromotions = createAsyncThunk(
    'promotion/fetchPromotions',
    async (params: { country: Country; reader: string; status: PromotionStatus }) => {
        try {
            const response = await axios.get(
                `/api/promotions/filtered?reader=${params.reader}&country=${params.country}&status=${params.status}`,
            )
            return response.data
        } catch (err) {
        }
    },
)

export const fetchCountryData = createAsyncThunk('promotion/fetchCountryData', async (country: Country) => {
    try {
        const response = await axios.get(`/api/reader/countryData/${country}`)
        return response.data
    } catch (err) {
    }
})

export const updateServer = createAsyncThunk('promotion/updateServer', async (country: Country) => {
    try {
        // const response = await axios.get('http://localhost:8080/api/promotions/updateServer' + reader, {timeout: 10000})
        const response = await axios.post(`/api/promotions/update-db/${country}`)
        return response.data
    } catch (err) {
    }
})

export const readByReader = createAsyncThunk('promotion/readByReader', async (request: ReaderRequest) => {
    try {
        const response = await axios.post('/api/reader', request, {
            timeout: 600000,
        })
        return response.data
    } catch (err) {
    }
})

export const readManually = createAsyncThunk('promotion/readManually', async (request: ReaderRequest) => {
    try {
        const response = await axios.post('/api/reader/manually', request, {
            timeout: 600000,
        })
        return response.data
    } catch (err) {
    }
})

export const copyLast = createAsyncThunk('promotion/copyLast', async (request: ReaderRequest) => {
    try {
        const response = await axios.post('/api/reader/copyLast', request)
        return response.data
    } catch (err) {
    }
})

const replaceItem = (oldItem: Item, newItem: Item) => {
    return oldItem.id === newItem.id ? newItem : oldItem
}
export const promotionSlice = createSlice({
    name: 'promotion',
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<Item>) => {
        //    state.promotions?.map{ (promotion:Promotion)=> return {...promotion, items: promotion.items.} }
        //     // state.promotions += action.payload
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(copyLast.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(copyLast.fulfilled, (state, action) => {
                state.status = 'idle'
                state.promotions = action.payload
            })
            .addCase(copyLast.rejected, (state) => {
                state.errorMessage = 'Fallo la copia de promociones intente de nuevo'
                state.status = 'failed'
            })
            .addCase(fetchPromotions.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchPromotions.fulfilled, (state, action) => {
                state.status = 'idle'
                state.promotions = action.payload
            })
            .addCase(fetchPromotions.rejected, (state) => {
                state.errorMessage = 'Fallo la lectura de promociones intente de nuevo'
                state.status = 'failed'
            })
            .addCase(fetchCountryData.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchCountryData.fulfilled, (state, action) => {
                state.status = 'idle'
                state.countryData = action.payload
            })
            .addCase(fetchCountryData.rejected, (state) => {
                state.errorMessage = 'Fallo la lectura de CountryData intente de nuevo'
                state.status = 'failed'
            })
            .addCase(updateServer.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updateServer.fulfilled, (state, action) => {
                state.status = 'idle'
                state.promotions = []
                state.updated = action.payload
            })
            .addCase(updateServer.rejected, (state) => {
                state.errorMessage = 'Fallo la actualizacion de promociones intente de nuevo'
                state.status = 'failed'
            })
            .addCase(readByReader.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(readByReader.fulfilled, (state, action) => {
                state.status = 'idle'
                state.promotions = action.payload
            })
            .addCase(readByReader.rejected, (state) => {
                state.errorMessage =
                    'Fallo la lectura automatica de promociones intente de nuevo, en caso de persistir comunicarse con el administrador'
                state.status = 'failed'
            })
    },
})

export const promotionState = (state: RootState) => state.promotion

export default promotionSlice.reducer
