import React, { useEffect, useState } from 'react'

// import { useAppDispatch } from "../../app/hooks";
import { Button, Col, Form, Row } from 'react-bootstrap'
import AirlineName from '../admin/AirlineName'
import PromotionView from '../admin/PromotionView'
import { Promotion } from '../../models/Promotion.model'
import { Country } from '../../models/enums/Country.model'
import { PromotionStatus } from '../../models/enums/PromotionStatus.model'
import { useAppDispatch, useAppSelector } from '../../components/redux/hooks'
import {
    copyLast,
    fetchCountryData,
    fetchPromotions,
    promotionState,
    readByReader,
} from '../../components/redux/reducers/promotionSlice'
import CountryComponent from '../admin/CountryComponent'
import { Reader } from '../../models/CountryData.model'
import { openTab } from '../../helpers/utils'

export function PromotionsView() {
    const { promotions, status, errorMessage, updated, countryData } = useAppSelector(promotionState)
    const dispatch = useAppDispatch()
    const [reader, setReader] = useState<Reader | null>(null)
    const [country, setCountry] = useState(Country.AR)
    const [updateReader, setUpdateReader] = useState(true)
    const [manualData, setManualData] = useState('')
    const [stringData, setStringData] = useState('')

    useEffect(() => {
        if (reader) {
            dispatch(fetchPromotions({ country, reader: reader.reader, status: PromotionStatus.READY }))
        }
    }, [reader])

    useEffect(() => {
        dispatch(fetchCountryData(country))
    }, [country])

    useEffect(() => {
        if (countryData !== null && countryData.readers.length > 0 && updateReader) {
            setReader(countryData.readers[0].reader)
            setUpdateReader(false)
        }
    }, [countryData])

    useEffect(() => {
        dispatch(fetchCountryData(country))
    }, [promotions])

    const readPromotions = (manual: boolean) => {
        if (reader) {
            dispatch(readByReader({ reader: reader.reader, country, stringData: manual ? stringData : '' }))
        }
    }

    const copyLastOne = () => {
        if (reader) {
            dispatch(copyLast({ reader: reader.reader, country, stringData: '' }))
        }
    }
    return (
        <Row className="m-3">
            <CountryComponent
                setCountry={setCountry}
                countryData={countryData}
                country={country}
                setUpdateReader={() => setUpdateReader(true)}
                showUpdateData= {false}
            />
            <Row>
                <Col sm="2" className="border">
                    {countryData !== null &&
                        countryData.readers.length > 0 &&
                        countryData.readers.map((r, i) => (
                            <AirlineName
                                key={i}
                                name={r.reader.name}
                                read={r.promotions > 0}
                                selectFunc={() => setReader(r.reader)}
                            />
                        ))}
                </Col>
                <Col sm="10" className="border">
                    <br />
                    <Row>
                        {promotions &&
                            reader &&
                            promotions.map((promotion, i) => (
                                <PromotionView
                                    promotion={promotion}
                                    key={`item-${i}`}
                                    reloadPromotions={() =>
                                        dispatch(
                                            fetchPromotions({
                                                country,
                                                reader: reader.reader,
                                                status: PromotionStatus.READY,
                                            }),
                                        )
                                    }
                                />
                            ))}
                    </Row>
                </Col>
            </Row>
        </Row>
    )
}

export default PromotionsView
