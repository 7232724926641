import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { openTab } from '../../helpers/utils'
import { Promotion } from '../../models/Promotion.model'
import { ItemView } from './ItemView'
export interface Props {
    promotion: Promotion
    reloadPromotions: () => void
}
export default function PromotionView({ promotion, reloadPromotions }: Props) {
    return (
        <>
            <Row className="mt-3">
                <Row className="mb-3">
                    <Col md="6">
                        <h4 className="mt-2">{`${promotion.name} - ${promotion.company}`}</h4>
                    </Col>
                    <Col md="2">
                        <Button variant="link" onClick={() => openTab(promotion.url)}>
                            Ver Pagina
                        </Button>
                    </Col>
                    {/* <Col sm="2">
                        <Button>Validar</Button>
                    </Col> */}
                </Row>
                <Row>
                    {promotion.items != null &&
                        promotion.items.map((item, i) => (
                            <ItemView item={item} index={i} key={`item-${i}`} reloadPromotions={reloadPromotions} />
                        ))}
                </Row>
            </Row>
        </>
    )
}
