import React from 'react'

import { Redirect, Switch, Route, Router } from 'react-router-dom'
import {PrivateRoute} from './PrivateRoute'
import { history } from './history'
import Admin from '../../features/admin/Admin'
import Login from '../../pages/login/Login'
import Home from '../../pages/home/Home'
import { AuthorityType } from '../../models/enums/AuthorityType.model'
import PromotionsView from '../../features/promotions/PromotionsView'

export function Routes() {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/admin" component={Admin} /> */}
                <PrivateRoute exact path="/admin" component={Admin} hasAnyAuthorities={[AuthorityType.ROLE_ADMIN, AuthorityType.ROLE_MODERATOR]} />
                <PrivateRoute exact path="/promotions" component={PromotionsView} hasAnyAuthorities={[AuthorityType.ROLE_MARKETING, AuthorityType.ROLE_ADMIN]} />
                <Route exact path="/login" component={Login} />
                <Redirect to="/" />
            </Switch>
        </Router>
    )
}
