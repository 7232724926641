import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../components/redux/hooks'
import { authenticationState, fetchUser } from '../../components/redux/reducers/authenticationSlice'

export default function Home() {
    const { status, user } = useAppSelector(authenticationState)
    // const dispatch = useAppDispatch()
    // console.log(user)
    // useEffect(() => {
    //   dispatch(fetchUser())
    // }, [])
    return (
        <div>
            <h3>Fly Promotion</h3>
            {user && <h5> Hola {user.username}</h5>}
            <Link to={`admin`} ><Button>admin</Button></Link>
        </div>
    )
}
