import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../components/redux/hooks'
import { fetchCountryData, promotionState, updateServer } from '../../components/redux/reducers/promotionSlice'
import { CountryData } from '../../models/CountryData.model'
import { Country } from '../../models/enums/Country.model'

interface Props {
    setCountry: (c: Country) => void
    setUpdateReader: () => void
    country: Country
    countryData: CountryData | null
    showUpdateData: boolean
}

const CountryComponent = ({ setCountry, countryData, country, setUpdateReader, showUpdateData }: Props) => {
    const { status, errorMessage } = useAppSelector(promotionState)
    const dispatch = useAppDispatch()
    const [countryName, setCountryName] = useState('Argentina')
    const [shouldUpdate, setShouldUpdate] = useState(false)

    useEffect(() => {
        if (shouldUpdate && status !== 'loading') {
            setShouldUpdate(false)
            dispatch(fetchCountryData(country))
        }
    }, [status])

    const updateServerDb = () => {
        setShouldUpdate(true)
        dispatch(updateServer(country))
    }

    const setLocalCountry = (countryName: string) => {
        setUpdateReader()
        switch (countryName) {
            case 'AR': {
                setCountryName('Argentina')
                setCountry(Country.AR)
                break
            }
            case 'CL': {
                setCountryName('Chile')
                setCountry(Country.CL)
                break
            }
            case 'BR': {
                setCountryName('Brasil')
                setCountry(Country.BR)
                break
            }
            case 'UY': {
                setCountryName('Uruguay')
                setCountry(Country.UY)
                break
            }
            case 'PY': {
                setCountryName('Paraguay')
                setCountry(Country.PY)
                break
            }
            default: {
                setCountryName('Argentina')
                setCountry(Country.AR)
            }
        }
    }
    return (
        <div>
            <Row>
                <Col md="2">
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="countrySelector">País: {countryName}</Form.Label>
                            <Form.Select id="countrySelector" onChange={(e) => setLocalCountry(e.target.value)}>
                                <option value={'AR'}>Argentina</option>
                                <option value={'BR'}>Brasil</option>
                                <option value={'CL'}>Chile</option>
                                <option value={'PY'}>Paraguay</option>
                                <option value={'UY'}>Uruguay</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row></Row>
                </Col>
                {showUpdateData && (
                    <>
                        <Col md="3" className=" mt-3 justify-content-center">
                            <h6>Ultima Actualizacion: {countryData?.lastUpdatedDate}</h6>
                            <h6>Total de promociones leidas: {countryData?.totalPromotions}</h6>
                            <h6>Total de vuelos leidos: {countryData?.totalItems}</h6>
                        </Col>
                        <Col md="2" className=" mt-3 justify-content-center">
                            <Button
                                variant="success"
                                disabled={countryData === null || !countryData?.canUpdate || status === 'loading'}
                                onClick={updateServerDb}
                            >
                                {' '}
                                Actualizar Servidor
                            </Button>
                        </Col>
                    </>
                )}
            </Row>
            <hr />
        </div>
    )
}

export default CountryComponent
