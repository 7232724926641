import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { IoEnterOutline } from 'react-icons/io5'

export interface Props {
    name: string
    read: boolean
    selectFunc: () => void
}
export default function AirlineName(props: Props) {
    return (
        <Row className="w-100 mt-2">
            <Col sm="2">
                {props.read && (
                    <IconContext.Provider value={{ color: 'green' }}>
                        <IoMdCheckmarkCircle />
                    </IconContext.Provider>
                )}
            </Col>
            <Col sm="8">
                <h6 className="mt-2">{props.name}</h6>
            </Col>
            <Col sm="2">
                <Button onClick={props.selectFunc}>
                    <IoEnterOutline />
                </Button>
            </Col>
        </Row>
    )
}
