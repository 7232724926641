import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthorityType } from '../../models/enums/AuthorityType.model'
import { Loader } from '../loader'
import { useAppSelector } from '../redux/hooks'
import { authenticationState } from '../redux/reducers/authenticationSlice'

export const PrivateRoute = ({ component: Component, hasAnyAuthorities = [], ...rest }: any) => {
    const { user, status, sessionHasBeenFetched, isAuthenticated } = useAppSelector(authenticationState)

    const isAuthorized = () => {
        if (user && user.authorities && user.authorities.length > 0) {
            if (hasAnyAuthorities.length === 0) {
                return true
            }
            return hasAnyAuthorities.some((a: AuthorityType) => user.authorities.includes(a))
        }
    }
        
    const checkAuthorities = (props: any) =>
    isAuthorized() ? (
        <Component {...props} />
    ) : (
      <div className="insufficient-authority">
        <div className="alert alert-danger">
          No tienes suficientes permisos para ingresar en esta pagina.
        </div>
      </div>
    );

  const renderRedirect = (props: any) => {
    if (!sessionHasBeenFetched) {
      return <Loader/>
    } else {
      return isAuthenticated ? (
        checkAuthorities(props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            search: props.location.search,
            state: { from: props.location }
          }}
        />
      );
    }
  };

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);

  return <Route {...rest} render={renderRedirect} />
}
