import React, { useEffect, useState } from 'react'

// import { useAppDispatch } from "../../app/hooks";
import { Button, Col, Form, Row } from 'react-bootstrap'
import AirlineName from './AirlineName'
import PromotionView from './PromotionView'
import { Promotion } from '../../models/Promotion.model'
import { Country } from '../../models/enums/Country.model'
import { PromotionStatus } from '../../models/enums/PromotionStatus.model'
import { useAppDispatch, useAppSelector } from '../../components/redux/hooks'
import {
    copyLast,
    fetchCountryData,
    fetchPromotions,
    promotionState,
    readByReader,
} from '../../components/redux/reducers/promotionSlice'
import CountryComponent from './CountryComponent'
import { Reader } from '../../models/CountryData.model'
import { openTab } from '../../helpers/utils'

export function Admin() {
    const { promotions, status, errorMessage, updated, countryData } = useAppSelector(promotionState)
    const dispatch = useAppDispatch()
    const [reader, setReader] = useState<Reader | null>(null)
    const [country, setCountry] = useState(Country.AR)
    const [updateReader, setUpdateReader] = useState(true)
    const [manualData, setManualData] = useState('')
    const [stringData, setStringData] = useState('')

    useEffect(() => {
        if (reader) {
            dispatch(fetchPromotions({ country, reader: reader.reader, status: PromotionStatus.DRAFT }))
        }
    }, [reader])

    useEffect(() => {
        dispatch(fetchCountryData(country))
    }, [country])

    useEffect(() => {
        if (countryData !== null && countryData.readers.length > 0 && updateReader) {
            setReader(countryData.readers[0].reader)
            setUpdateReader(false)
        }
    }, [countryData])

    useEffect(() => {
        dispatch(fetchCountryData(country))
    }, [promotions])

    const readPromotions = (manual: boolean) => {
        if (reader) {
            dispatch(readByReader({ reader: reader.reader, country, stringData: manual ? stringData : '' }))
        }
    }

    const copyLastOne = () => {
        if (reader) {
            dispatch(copyLast({ reader: reader.reader, country, stringData: '' }))
        }
    }
    return (
        <Row className="m-3">
            <CountryComponent
                setCountry={setCountry}
                countryData={countryData}
                country={country}
                setUpdateReader={() => setUpdateReader(true)}
                showUpdateData= {true}
                
            />
            <Row>
                <Col sm="2" className="border">
                    {countryData !== null &&
                        countryData.readers.length > 0 &&
                        countryData.readers.map((r, i) => (
                            <AirlineName
                                key={i}
                                name={r.reader.name}
                                read={r.promotions > 0}
                                selectFunc={() => setReader(r.reader)}
                            />
                        ))}
                </Col>
                <Col sm="10" className="border">
                    <Row className='mt-3'>
                        <Col md="3">
                            {reader && (
                                <>
                                    <Row className="ms-2">Lector: {reader.name}</Row>
                                    <Row className="ms-2">Promociones: {promotions?.length}</Row>
                                    <Row className="ms-2">
                                        <Button variant="link" onClick={() => openTab(reader.url)}>
                                            {reader?.url}
                                        </Button>
                                    </Row>
                                </>
                            )}
                        </Col>
                        <Col md="2">
                            <Button
                                variant="success"
                                onClick={() => readPromotions(false)}
                                disabled={status === 'loading'}
                            >
                                {status === 'loading' ? `Leyendo ...` : `Lectura Automática`}
                            </Button>
                        </Col>
                        <Col md="5">
                            <Row>
                                <textarea
                                    placeholder="Pegar datos copiados"
                                    value={stringData}
                                    onChange={(e) => setStringData(e.target.value)}
                                />
                            </Row>
                            <Row className="mt-3">
                                <Col align="center">
                                    <Button
                                        onClick={() => setStringData('')}
                                        disabled={status === 'loading' || stringData === ''}
                                    >
                                        Borrar
                                    </Button>
                                </Col>
                                <Col align="center">
                                    <Button
                                        variant="success"
                                        onClick={() => readPromotions(true)}
                                        disabled={status === 'loading' || stringData === ''}
                                    >
                                        {status === 'loading' ? `Leyendo ...` : `Lectura Manual`}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="2" align="center">
                            <Button variant="info" onClick={copyLastOne} disabled={status === 'loading'}>
                            {status === 'loading' ? `Copiando ...` : `Copiar Anterior`}</Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        {promotions &&
                            reader &&
                            promotions.map((promotion, i) => (
                                <PromotionView
                                    promotion={promotion}
                                    key={`item-${i}`}
                                    reloadPromotions={() =>
                                        dispatch(
                                            fetchPromotions({
                                                country,
                                                reader: reader.reader,
                                                status: PromotionStatus.DRAFT,
                                            }),
                                        )
                                    }
                                />
                            ))}
                    </Row>
                </Col>
            </Row>
        </Row>
    )
}

export default Admin
