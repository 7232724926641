import React, { useState } from 'react'
import { Button, Card, CardImg, Col, Row, Table } from 'react-bootstrap'
import { useAppDispatch } from '../../components/redux/hooks'
import { reset } from '../../components/redux/reducers/itemSlice.ts'
import { openTab } from '../../helpers/utils'
import { Item } from '../../models/Item.model'
import ItemEditModal from './ItemEditModal'

interface Props {
    item: Item
    index: number
    reloadPromotions: () => void
}
export const ItemView = ({ item, index, reloadPromotions }: Props) => {
    const dispatch = useAppDispatch()
    const [showEdit, setShowEdit] = useState(false)
    const edit = () => {
        dispatch(reset)
        setShowEdit(true)
    }

    return (
        <>
            <Col sm="3" className="mb-3">
                {/* <Card style={{ width: '22rem', padding: '0px', marginRight: '0.50rem', marginBottom: '0.50rem' }}> */}
                <Card>
                    <Card.Body style={{ padding: '0.75rem' }}>
                        <Row>
                            <Col sm="8">
                                <Card.Title>{item.origin}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{item.destination}</Card.Subtitle>
                                <Card.Subtitle>{item.description}</Card.Subtitle>
                            </Col>
                            <Col sm="4">
                                <CardImg
                                    src={
                                        item.imageUrl
                                            ? item.imageUrl
                                            : 'https://flypromociones.s3.us-east-2.amazonaws.com/noimage.png'
                                    }
                                    width="100%"
                                />
                            </Col>
                        </Row>
                        <Card.Subtitle style={{ color: 'blue' }} onClick={() => openTab(item.link)} className="mt-1">
                            {item.link && item.link.substring(0, 40)}
                        </Card.Subtitle>
                        <Row className="m-2" align="center">
                            <Card.Subtitle className="mb-2 text-muted">{item.price}</Card.Subtitle>
                        </Row>
                        <Row className="justify-content-center mt-2">
                            <Button variant="warning" onClick={() => edit()} style={{ width: 'auto' }}>
                                Editar
                            </Button>
                        </Row>
                    </Card.Body>
                </Card>
                {/* {editImage && (
        <PickImage openModal={editImage} item={item} index={index} toggle={() => setEditImage(false)} />
      )} */}
            </Col>
            {showEdit && (
                <ItemEditModal
                    show={showEdit}
                    item={item}
                    handleClose={() => setShowEdit(false)}
                    reloadPromotions={reloadPromotions}
                />
            )}
        </>
    )
}
