import React, { useEffect } from 'react'
import './App.css'
import moment from 'moment'
import { setAuthToken } from './helpers/setAuthToken'
import { Routes } from './components/router/routes'
import axios from 'axios'
import { useAppDispatch } from './components/redux/hooks'
import { fetchUser } from './components/redux/reducers/authenticationSlice'
import { auth } from './modeluies/firebase/config'

const token = localStorage.getItem('token')
if (token) {
    setAuthToken(token)
}
// axios.defaults.baseURL = 'http://localhost:8080'
axios.defaults.baseURL = 'https://admini.flypromociones.com'

function App() {
    moment.locale('es')
    const dispatch = useAppDispatch()
    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
              // El usuario ha iniciado sesión
              dispatch(fetchUser())
            } else {
                dispatch(fetchUser())
            }
          });
        
    }, [])

    return (
        <div className="App">
            <header className="App-header">
                <Routes />
            </header>
        </div>
    )
}

export default App
