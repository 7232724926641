import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
import counterReducer from '../../features/counter/counterSlice'
import promotionReducer from './reducers/promotionSlice'
import itemReducer from './reducers/itemSlice.ts'
import authenticationReducer from './reducers/authenticationSlice'

const rootReducer = combineReducers({
    counter: counterReducer,
    promotion: promotionReducer,
    item: itemReducer,
    authentication: authenticationReducer
})

export default rootReducer
